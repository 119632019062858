import React, { FC, ReactNode } from "react";

import parse from "html-react-parser";
import Link from "next/link";

import Divider from "@busbud/design-system-components/dist/Divider";
import Heading from "@busbud/design-system-components/dist/Heading";
import { LongArrowRightMD } from "@busbud/design-system-components/dist/Icons";
import Label from "@busbud/design-system-components/dist/Label";
import Stack from "@busbud/design-system-components/dist/Stack";
import { makeStyles } from "@busbud/design-system-components/dist/styles";
import Text from "@busbud/design-system-components/dist/Text";

const useStyles = makeStyles(
  ({ busbud: { spacing, styles, breakpoints } }) => ({
    link: {
      color: "unset",
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      padding: spacing(1.5, 1.5),
      [breakpoints.up("sm")]: {
        padding: spacing(2.5, 3.5),
      },

      "&:hover": {
        "& $underline": {
          opacity: 1,
          transform: "translateY(0)",
        },
      },
    },
    icon: {
      flexShrink: 0,
      marginLeft: "auto",
    },
    bullet: {
      height: spacing(1),
      width: spacing(1),
      flexShrink: 0,
      borderRadius: styles.rounding.circle,
    },
    heading: {
      margin: spacing(0, 1.5, 2),
      [breakpoints.up("sm")]: {
        margin: spacing(0, 3.5, 3),
      },
      "& a": {
        textDecoration: "none",
        color: "unset",
      },
    },
    snippet: {
      marginLeft: spacing(2.5),
    },
    label: {
      overflow: "hidden",
      position: "relative",
      padding: spacing(0.5, 0),
    },
    underline: {
      opacity: 0,
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "0.1em",
      transition: "opacity 500ms, transform 300ms",
      transform: "translateY(100%)",
    },
  })
);

export type ListItem = {
  id: string | number;
  title: string;
  path: string;
  color?: string;
  snippet?: string;
};

type Props = {
  title?: string | ReactNode;
  items: ListItem[];
  dataCy?: string;
  endIcon?: boolean;
  divider?: boolean;
  alignTitle?: "center" | "left";
};

export const BulletedList: FC<Props> = ({
  title,
  items,
  dataCy,
  endIcon,
  divider,
  alignTitle = "center",
}) => {
  const classes = useStyles();

  if (!items.length) {
    return null;
  }

  return (
    <div>
      {!!title && (
        <Heading
          component="h2"
          size={{ xs: "sm", sm: "md" }}
          display="block"
          align={alignTitle}
          className={classes.heading}
        >
          {title}
        </Heading>
      )}
      <Stack direction="column" component="ul" data-cy={dataCy}>
        {items.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <li>
                <Link
                  prefetch={false}
                  href={item.path}
                  locale={false}
                  className={classes.link}
                >
                  <Stack direction="column" spacing={{ xs: 1, sm: 2 }} mr={2}>
                    <Stack alignItems="center" spacing={1.5}>
                      <span
                        className={classes.bullet}
                        style={{ backgroundColor: item.color }}
                      />
                      <Label
                        size={{ xs: "md", sm: "xl" }}
                        fontWeight="bold"
                        className={classes.label}
                      >
                        {item.title}
                        <span
                          className={classes.underline}
                          style={{ backgroundColor: item.color }}
                        />
                      </Label>
                    </Stack>
                    {!!item.snippet && (
                      <Text
                        size={{ xs: "md", md: "lg" }}
                        color="text.secondary.opaque"
                        className={classes.snippet}
                      >
                        {parse(item.snippet)}
                      </Text>
                    )}
                  </Stack>
                  {endIcon && (
                    <LongArrowRightMD
                      className={classes.icon}
                      color="icon.primary.opaque"
                    />
                  )}
                </Link>
              </li>
              {divider && index !== items.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </Stack>
    </div>
  );
};
